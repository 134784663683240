




































































import { mdiAccount, mdiHelp } from '@mdi/js';
import Vue from 'vue';
import TheHeaderButtonInfo from '@/components/generic/TheHeaderButtonInfo.vue';
import TheHelpButton from '@/components/generic/TheHelpButton.vue';
import TheNavigationMenu from '@/components/generic/TheNavigationMenu.vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import settings from '@/settings';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';

const AuthService = ServiceFactory.get('auth');

export default Vue.extend({
  name: 'TheContainerDefault',

  components: {
    TheHeaderButtonInfo,
    TheHelpButton,
    TheNavigationMenu,
  },

  data(): {
    deployTargetName: string;
    icons: {
      [key: string]: string;
    };
    intervalID: number | undefined;
    showedDialog: {
      [key: string]: boolean;
    };
  } {
    return {
      deployTargetName: settings.deployTargetName,
      icons: {
        mdiAccount,
        mdiHelp,
      },
      intervalID: undefined,
      showedDialog: {
        logout: false,
      },
    };
  },

  computed: {
    ...DomainAuthMapper.mapState({
      viewName: (state) => `${state.userAttributes.family_name} ${state.userAttributes.given_name}`,
    }),
    ...DomainAuthMapper.mapGetters(['isAuthenticated']),
  },

  created() {
    const self = this;

    self.intervalID = window.setInterval(() => {
      if (!self.isAuthenticated(self.$$dayjs())) {
        if (self.$router.currentRoute.name !== 'Login') {
          self.$router.replace('/login');
        }

        if (self.intervalID !== undefined) {
          clearInterval(self.intervalID);

          self.intervalID = undefined;
        }
      }
    }, 1000);
  },

  beforeDestroy() {
    if (this.intervalID !== undefined) {
      clearInterval(this.intervalID);

      this.intervalID = undefined;
    }
  },

  methods: {
    ...UICommonMapper.mapActions([
      'setErrorMessage',
      'setNavigating',
      'toggleShowedNavigationMenu',
    ]),

    gotoOtherPage(url: string) {
      if (this.$route.path !== url) {
        this.$router.push(url);
      }
    },

    async logout() {
      const self = this;

      self.setNavigating({ navigating: true });

      try {
        await AuthService.logout();
      } catch (error) {
        self.$$log.error(error);
        self.setErrorMessage({ text: error.message });
      }

      // APIがエラーを返した場合でもログアウトを行う
      this.$router.push('/logout');
    },
  },
});
