import InfoService from '@/services/strapi/InfoService';

const services = {
  info: InfoService,
};

export default {
  get<K extends keyof typeof services>(name: K): typeof services[K] {
    return services[name];
  },
};
