























import _ from 'lodash';
import Vue from 'vue';
import { UICommonMapper } from '@/store/modules/ui/common';
import type { RouteConfig } from 'vue-router';

type MenuItem = {
  icon: string;
  path: string;
  title: string;
};

// ルートのパスとメタフィールドを基にナビゲーションドロワーのメニュー項目を生成する
function generateMenuItems(
  routes: RouteConfig[] | undefined,
  menuItems: MenuItem[],
  parentPath = ''
) {
  _.forEach(routes, (route: RouteConfig) => {
    const path = _.get(route, 'path');
    let pathPrefix = '';

    if (parentPath.length > 0 && !_.endsWith(parentPath, '/')) {
      pathPrefix = `${parentPath}/`;
    } else {
      pathPrefix = parentPath;
    }

    if (_.get(route, 'meta.isMenuItem')) {
      const icon = _.get(route, 'meta.icon');
      const title = _.get(route, 'meta.title');

      menuItems.push({ icon, path: pathPrefix + path, title });
    }

    const children = _.get(route, 'children');

    if (_.isArray(children)) {
      generateMenuItems(children, menuItems, pathPrefix + path);
    }
  });
}

export default Vue.extend({
  name: 'TheNavigationMenu',

  data(): {
    menuItems: MenuItem[];
  } {
    return {
      menuItems: [],
    };
  },

  computed: {
    ...UICommonMapper.mapState(['showedNavigationMenu']),

    computedShowed: {
      get(): boolean {
        return this.showedNavigationMenu;
      },
      set(value: boolean) {
        this.setShowedNavigationMenu({ showed: value });
      },
    },
  },

  created() {
    const { routes } = this.$router.options;

    generateMenuItems(routes, this.menuItems);
  },

  methods: {
    ...UICommonMapper.mapActions(['setShowedNavigationMenu']),
  },
});
