





















































import { mdiEmailOutline, mdiEmailOpenOutline, mdiBell } from '@mdi/js';
import DOMPurify from 'dompurify';
import marked from 'marked';
import Vue from 'vue';
import ServiceFactory from '@/services/strapi/ServiceFactory';
import settings from '@/settings';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { DomainStrapiInfoMapper } from '@/store/modules/domainStrapi/info';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIInfoMapper } from '@/store/modules/ui/info';
import type { Info } from '@/store/modules/domainStrapi/info';

// お知らせリストアイテム
type InfoListItem = {
  // お知らせ
  info: Info;
  // 既読かどうか
  read: boolean;
  // 更新済みかどうか
  updated: boolean;
};

const InfoService = ServiceFactory.get('info');

export default Vue.extend({
  name: 'TheHeaderButtonInfo',

  data(): {
    gotInfos: boolean;
    icons: {
      [key: string]: string;
    };
    indexOpenedExpansionPanel: number | undefined;
    infoList: InfoListItem[];
    showedDialog: {
      info: boolean;
    };
  } {
    return {
      // お知らせを取得済みかどうか
      gotInfos: false,
      // アイコン
      icons: {
        mdiBell,
        mdiEmailOpenOutline,
        mdiEmailOutline,
      },
      // 開いているエクスパンションパネルのインデックス
      indexOpenedExpansionPanel: 0,
      // お知らせリスト
      infoList: [],
      // ダイアログ開閉用フラグ
      showedDialog: {
        info: false,
      },
    };
  },

  computed: {
    ...DomainAuthMapper.mapState({ partnerId: (state) => state.userAttributes.partner_id }),
    ...DomainStrapiInfoMapper.mapState(['infos']),
    ...UIInfoMapper.mapGetters(['readInfoIds']),

    // マークダウンプレビューHTML
    markdownPreviews(): string[] {
      return this.infoList.map((item) => {
        return DOMPurify.sanitize(marked.parse(item.info.text));
      });
    },

    // お知らせダイアログタイトル
    title(): string {
      if (this.unreadCount > 0) {
        return `お知らせ (未読: ${this.unreadCount}件)`;
      }
      return 'お知らせ';
    },

    // 未読件数
    unreadCount(): number {
      const unreadInfos = this._.filter(this.infoList, ['read', false]);

      return unreadInfos.length;
    },
  },

  watch: {
    // エクスパンションパネルが開かれた際、対応するお知らせを既読にする
    indexOpenedExpansionPanel(value) {
      if (value !== undefined) {
        this.markAsRead(value);
      }
    },

    // お知らせダイアログが閉じられた際、エクスパンションパネルの先頭を開く
    'showedDialog.info': {
      handler(value) {
        if (!value) {
          this.indexOpenedExpansionPanel = 0;
        }
      },
    },
  },

  async created() {
    const self = this;

    try {
      if (self.infos === undefined) {
        const infos: Info[] = await InfoService.getInfos({ _sort: 'published_at:desc' });
        const regExp = /(!\[.*\]\()\/(uploads\/.+\))/;

        let baseURL = settings.strapiImageBaseURL;

        if (!self._.endsWith(baseURL, '/')) {
          baseURL += '/';
        }

        // 画像URL修正
        self._.map(infos, (info) => {
          // 修正結果の保存に必要なため除外
          // eslint-disable-next-line no-param-reassign
          info.text = info.text.replace(regExp, `$1${baseURL}$2`);
        });
        self.setInfos({ infos });
      }

      const readInfoIds = self.readInfoIds(self.partnerId);
      const infoList: InfoListItem[] = [];

      self._.forEach(self.infos, (info) => {
        const read = readInfoIds.includes(info.id);

        // 公開日時の設定と更新にラグがあるため、10秒以内の差は無視する
        const publishedAtPlus10Seconds = self.$$dayjsParse(info.published_at).add(10, 'seconds');
        const updatedAt = self.$$dayjsParse(info.updated_at);

        const updated = updatedAt.isAfter(publishedAtPlus10Seconds);

        infoList.push({
          info,
          read,
          updated,
        });
      });

      self.infoList = infoList;

      self.gotInfos = true;
    } catch (error) {
      self.$$log.error(error);
      self.setErrorMessage({ text: error.message });
    }
  },

  methods: {
    ...DomainStrapiInfoMapper.mapActions(['setInfos']),
    ...UICommonMapper.mapActions(['setErrorMessage']),
    ...UIInfoMapper.mapActions(['addReadInfoId']),

    // お知らせを既読にする
    markAsRead(index: number) {
      this.addReadInfoId({
        infoId: this.infoList[index].info.id,
        partnerId: this.partnerId,
      });

      if (!this.infoList[index].read) {
        this.infoList[index].read = true;
      }
    },

    // お知らせダイアログを表示する
    async showDialog() {
      if (this.infoList.length > 0) {
        this.markAsRead(0);
      }

      this.showedDialog.info = true;
    },
  },
});
