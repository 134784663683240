import axios from 'axios';
import _ from 'lodash';
import Log from '@/resources/plugins/Logger/Log';
import settings from '@/settings';

const Service = axios.create({
  baseURL: settings.strapiAPIEndpoint,
  headers: {
    'Content-Type': 'application/json',
  },
});

Service.interceptors.request.use(
  (config) => {
    Log.debug('Request Config', config);

    return config;
  },
  (error) => {
    const newError = new Error();

    Log.error('Service Error', error);
    Log.error('Service Error Request', error.request);

    if (_.isString(error.message)) {
      newError.message = `不明なエラーが発生しています。:${error.message} [CRUI-Z-999-00001]`;
    } else {
      newError.message = `不明なエラーが発生しています。 [CRUI-Z-999-00001]`;
    }

    return Promise.reject(newError);
  }
);

Service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const newError = new Error();

    Log.error('Service Error', error);
    Log.error('Service Error Response', error.response);

    if (_.isPlainObject(error.response)) {
      const message = _.get(error, 'response.data.message');

      if (_.isString(message)) {
        newError.message = message;

        const code = _.get(error, 'response.data.code');

        if (_.isString(code)) {
          newError.message += ` [${code}]`;
        }
      } else {
        const statusText = _.get(error, 'response.statusText');

        if (_.isString(statusText)) {
          newError.message = statusText;
        } else {
          newError.message = _.get(error, 'response.status');
        }
      }
    } else if (_.isString(error.message)) {
      newError.message = `不明なエラーが発生しています。:${error.message} [CRUI-Z-999-00001]`;
    } else {
      newError.message = `不明なエラーが発生しています。 [CRUI-Z-999-00001]`;
    }

    return Promise.reject(newError);
  }
);

export default Service;
